
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;0,500;0,900;1,100&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* min-height: 75rem; */
  user-select: none;
}

code {
  font-family: 'Roboto', sans-serif;
}

.px-20-f {
  font-size: 20px;
  font-family: "Roboto";
  color: #393939;
  font-weight: bold;
}

.px-16-f {
  font-size: 16px;
  font-family: "Roboto";
  color: #393939;
}

.c-nav {
  position: sticky !important;
  z-index: 4;
  width: 100%;
  background-color: #ffffff !important;
}

.postion-relative-block {
  position: relative;
}



.color-text {
  color: white !important;
}
/* mobile header */
#offcanvasNavbar-expand-sm {
  visibility: visible;
  width: 321px;
  border-radius: 22px 0px 0px 15px;
}

/* scroll bar css */

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #03649d;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #03649d;
}

::-webkit-scrollbar:horizontal {
  background: #03649d;
  width: 10px !important;
  border-radius: 10px;
}

/* end of scroll bar css */

/* scroll top */
.rotate {
  transform: rotate(-90deg) !important;
}
.circle-rotate {
  position: fixed;
  bottom: 50px;
  right: 50px;
  height: 50px;
  width: 100%px;
  font-size: 50px;
  z-index: 5;
}
.arrow {
  position: fixed;
  bottom: 58px;
  right: 56px;
  font-size: 51px;
  width: 36px;
  border-radius: 27px;
  background-color: #1c75bc;
  z-index: 5;
}

.c-nav .navbar-toggler {
  border: none;
}
.navbar-toggler-icon {
  /* background-image: ; */
}

.css-1ri6ub7-MuiPaper-root-MuiCard-root{
  height: 26rem !important;
  width: 25rem !important;
  border-radius: 23px !important;
  padding: 1rem !important;
}
.css-1arijsr-MuiSnackbar-root {
  top: 88px !important;
  right: 24px;
  left: auto;
}
.snackbar{
  width: 3rem;
  height: 1rem;
  background-color: #1c75bc;
}
.loading {
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
  height: 100vh; /* Set height to full viewport height */
  color: #1e75bb;
  font-size: 30px;
}