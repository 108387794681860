.card-button {
  background: #1e75bb 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
  opacity: 1;
  position: absolute !important;
    bottom: 1.5rem !important;
}
.card-button-back{
  border: none;
  background-color: #ffff;
  position: absolute;
  top:0;
}